document.addEventListener('DOMContentLoaded', function () {
    const utmFields = [
        'utm_content', // UTM Content
        'utm_campaign', // UTM Campaign
        'utm_term', // UTM Term
        'utm_medium', // UTM Medium
        'utm_source', // UTM Source
    ];

    const miscFields = [
        'page_of_enquiry', // Page URL
    ];


    const utmURLValues = getUTMURLValues();


    addUTMValuesToFields();



    function getUTMURLValues() {
        const urlParams = new URLSearchParams(window.location.search);
        const utmValues = {};

        utmFields.forEach((field_name) => {
            const value = urlParams.get(field_name);
            if (value) {
                utmValues[field_name] = value;
            }
        });

        return utmValues;
    }

    function addUTMValuesToFields() {
        utmFields.forEach((field_name) => {
            const fields = document.querySelectorAll('[data-utmname="' + field_name + '"]');
            fields.forEach((field) => {
                console.log('field', field, utmURLValues[field_name])
                field.value = utmURLValues[field_name] || '';
            });
        });

        miscFields.forEach((field_name) => {
            const fields = document.querySelectorAll('[data-utmname="page_of_enquiry"]');
            fields.forEach((field) => {
                field.value = window.location.href;
            });
        });
    }
    

});